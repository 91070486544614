import React, { useEffect, useMemo, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios";

import Loader from "components/UI/loader";
import Banners from "components/UI/banners";

import { isEmpty } from "utils/helper";


export const blogContentQuery = graphql`
query BlogContentQuery {
  allContentfulBannerUpdated(
    filter: {type: {eq: "content"}, location: {eq: "blog-page"}}
  ) {
    nodes {
      title
      location
      type
      link {
        text
        type
        isArrowShow
        href
      }
    }
  }
}
`;

const BlogPosts = () => {
    const { allContentfulBannerUpdated } = useStaticQuery(blogContentQuery);
    const { title, link } = allContentfulBannerUpdated.nodes[0];

    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios
            .get("/api/v2/blog/posts")
            .then(response => setPosts(response.data))
            .finally(() => setIsLoading(false))
    }, [])

    const publishedPosts = useMemo(() => {
        const blogStructure = [
            "100%",
            "30%", "30%", "30%",
            "30%", "30%", "30%",
        ];
        const GATSBY_BIGCOMMERCE_STOREFRONT_URL = process.env.GATSBY_BIGCOMMERCE_STOREFRONT_URL;

        return posts
            .map((post, mapIndex) => {
                const { id, title, summary, thumbnail_path, url } = post;
                return {
                    node: {
                        id,
                        title,
                        plainText: summary,
                        imageSrc: {
                            file: {
                                url: GATSBY_BIGCOMMERCE_STOREFRONT_URL + thumbnail_path,
                            },
                            title,
                        },
                        link: {
                            text: link?.text ?? 'Read More',
                            type: link?.type ?? 'primary',
                            isArrowShow: link?.isArrowShow ?? true,
                            href: link?.href ?? url,
                        },
                        type: blogStructure[mapIndex % blogStructure.length],
                    },
                };
            }) ?? []
    }, [posts]);

    return (
        <div className="blog__content">
            {
                isLoading ? (
                    <Loader/>
                ) : (
                    <div className="blog__content-wrapper">
                        {
                            isEmpty(publishedPosts) ? (
                                <h2 className="typography__h3">
                                    No posts found
                                </h2>
                            ) : (
                                <Banners bannersTitle={title} banners={publishedPosts}/>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default BlogPosts;
