import React from "react";

import Main from "components/Layout/main";
import BlogPageHeroBanner from "components/UI/BlogPage/blogPageHeroBanner";
import BlogPosts from "components/UI/BlogPage/blogPageContent";

import "styles/blog-page.scss";
import StaticPageMeta from "../components/UI/staticPageMeta";

const BlogPage = (props) => {
    const gtmData = {
        page: {
            title: "Blog",
            type: "Static",
        },
    };

    return (
        <>
            <StaticPageMeta pageName="Blog page" />
            <Main className="blog" gtmData={gtmData}>
                <BlogPageHeroBanner />
                <BlogPosts />
            </Main>
        </>
    );
};

export default BlogPage;
