import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import Button from "components/UI/button";
import Image from "components/UI/image";
import { GTMSelectContentEvent } from "components/GTM/gtmStaticPage";

import "styles/banner.scss";

const Banner = (props) => {
    const {
        image,
        imageMobile,
        imageSrc,
        title,
        type,
        plainText,
        link,
        onClick,
        ...restProps
    } = props;

    const buttonClick = () => {
        onClick && onClick();

        GTMSelectContentEvent({
            content_type: title,
            content_id: '',
        });
    }

    const [bannerType, setBannerType] = useState('column-100');
    useEffect(() => {
        switch (type) {
            case '30%':
                setBannerType('column-30');
                break;
            case 'portrait30%':
                setBannerType('column-30-vertical');
                break;
            case '50%':
                setBannerType('column-50');
                break;
            case '100%':
                setBannerType('column-100');
                break;
            default:
                setBannerType('column-100');
                break;
        }
    }, [type]);

    const IsLink = ({ children }) =>
        link ? (
            <Link className="banner-link button-link" to={link?.href}>
                {children}
            </Link>
        ) : <>
            {children}
        </>

    return (
        <div className={`banner ${bannerType}`} {...restProps}>
            <IsLink>
                <div className='banner__picture-container'>
                    {
                        imageSrc ?
                            <Image src={imageSrc.file.url} className="banner__picture"/> :
                            <Image image={image} imageMobile={imageMobile} className="banner__picture"/>
                    }
                </div>
                <div className="banner__content">
                    {title && (
                        bannerType === 'column-100' ?
                            <h3 className="banner__content-title typography__h3">{title}</h3> :
                            <h4 className="banner__content-subtitle">{title}</h4>
                    )}
                    {
                        plainText &&
                        <p className="banner__content-plain-text typography__p--inter">
                            {plainText}
                        </p>
                    }
                    {
                        link && (
                            <Button
                                className="banner__content-button"
                                value={link?.text}
                                type={link?.type}
                                href={link?.href}
                                isArrowShow={link?.isArrowShow}
                                isThin={link?.isThin}
                                onClick={buttonClick}
                            />
                        )
                    }
                </div>
            </IsLink>
        </div>
    );
};

export default Banner;
