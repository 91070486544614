import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import HeroBanner from "components/UI/heroBanner";

export const blogHeroBannerQuery = graphql`
query BlogHeroBannerQuery {
  allContentfulBannerUpdated(
    filter: {type: {eq: "hero"}, location: {eq: "blog-page"}}
    limit: 1
  ) {
    nodes {
      id
      title
      plainText
      location
      type
      image {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
      imageMobile {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
      text {
        raw
      }
      name
      contentfulid
      contentful_page {
        contentfulid
        name
      }
    }
  }
}
`;

const BlogPageHeroBanner = () => {
    const { allContentfulBannerUpdated } = useStaticQuery(blogHeroBannerQuery);
    const heroBanner = allContentfulBannerUpdated.nodes[0];

    return (
        <HeroBanner {...heroBanner}/>
    );
};

export default BlogPageHeroBanner;
