import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { isNotEmpty } from "utils/helper";

import Button from "components/UI/button";
import Image from "components/UI/image";

import { GTMSelectPromotionEvent } from "components/GTM/gtmStaticPage";

import "styles/hero-banner.scss";
import { navigate } from "gatsby";

const HeroBanner = (props) => {
    const { title, plainText, text, link, image, imageMobile, type, ...restProps } = props;

    const buttonClick = () => {
        const contentful_page = restProps?.contentful_page ?? []
        GTMSelectPromotionEvent({
            creative_name: contentful_page[0]?.name ?? title,
            creative_slot: contentful_page[0]?.contentfulid ?? restProps.location,
            promotion_id: restProps.contentfulid,
            promotion_name: restProps.name,
        });

        navigate(link.href);
    }

    return (
        <div className={`hero-banner ${type}`} {...restProps}>
            {
                isNotEmpty(image) && (
                    <div className="background">
                        <Image image={image} imageMobile={imageMobile} loading="eager"/>
                    </div>
                )
            }
            <div className="content">
                {title && <p className="content__title typography__h1">{title}</p>}
                {plainText && <p className="content__description typography__subtitle">{plainText}</p>}
                {text && <p className="content__description typography__subtitle">{renderRichText(text)}</p>}
                {link &&
                    <Button className="content__button"
                            value={link.text}
                            isArrowShow={link.isArrowShow}
                            type={link.type}
                            onClick={buttonClick}/>
                }
            </div>
        </div>
    );
};

export default HeroBanner;
